@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';


#header-top {
  padding-bottom: 20px;

  .navbar {
    height: 164px;

    .lights-logo {
      width: 450px;
      top: 0;
    }
  }
}

.spot {
  opacity: .4;
}

.wave {
  position: fixed;
  bottom: -260px;
  width: 100%;
  opacity: .4;
}

.circle {
  opacity: .1;
}

.big-title {
  margin-top: 20px;
  font-size: 255px;
  font-weight: bold;
  text-align: center;
  line-height: 255px;
  color: #fff;

  @include media-breakpoint-down(md) {
    font-size: 200px;
    line-height: 200px;
  }
}

.subtitle {
  margin-bottom: 35px;
  text-align: center;
  font-size: 18px;
  line-height: 60px;
  color: #fff;
  font-weight: normal;

  @include media-breakpoint-down(md) {
    line-height: 20px;
  }
}